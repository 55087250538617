import Childcare from './images/category_label_icons/Childcare.svg';
import Emotional from './images/category_label_icons/Emotional.svg';
import General from './images/category_label_icons/General.svg';
import Home from './images/category_label_icons/Home.svg';
import LegalRights from './images/category_label_icons/LegalRights.svg';
import Medical from './images/category_label_icons/Medical.svg';
import t from './translations/translate.ts';





export function getColor(txt){
    if (txt.toLowerCase().includes("medical")){
        return {text: '#B4407F', background: '#FFF2F9'};
    } else if (txt.toLowerCase().includes("home")){
        return {text: '#AE8109', background: '#FFF7DF'};
    } else if (txt.toLowerCase().includes("childcare")){
        return {text: '#456C13', background: '#EFFFDB'};
    } else if (txt.toLowerCase().includes("emotional")){
        return {text: '#0B7F6C', background: '#E5FFFB'};
    } else if (txt.toLowerCase().includes("legal")){
        return {text: '#4A709A', background: '#EEF7FF'};
    } else if (txt.toLowerCase().includes("general")){
        return {text: '#65458E', background: '#F7F1FF'};
    } else {
        return {text: '#65458E', background: '#F7F1FF'};
    }
}



function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getIconLocaleText(txt){
    //return capitalizeFirstLetter(txt);
    if (txt.toLowerCase().includes("medical")){
        return t('category_medical');
    } else if (txt.toLowerCase().includes("home")){
        return t('category_home');
    } else if (txt.toLowerCase().includes("childcare")){
        return t('category_childcare');
    } else if (txt.toLowerCase().includes("emotional")){
        return t('category_emotional');
    } else if (txt.toLowerCase().includes("legal")){
        return t('category_legal');
    } else if (txt.toLowerCase().includes("general")){
        return t('category_general');
    }else{
        return t('category_general');
    }
}

export function getIconSrc(txt){ 
    if (txt.toLowerCase().includes("medical")){
        return Medical;
    } else if (txt.toLowerCase().includes("home")){
        return Home;
    } else if (txt.toLowerCase().includes("childcare")){
        return Childcare;
    } else if (txt.toLowerCase().includes("emotional")){
        return Emotional;
    } else if (txt.toLowerCase().includes("legal")){
        return LegalRights;
    } else if (txt.toLowerCase().includes("general")){
        return General;
    }else{
        return General;
    }
}

