// route: "/phone_login"
// state initial
// state loading
// state error
// state ready
// state phone_ok
// state phone error
// state code ok
// state code error

import { useState } from "react";
import Loader from "../Loader";
import PhoneLoginState from "../enums/PhoneLoginState";
import TextFieldType from '../enums/TextFieldTypes';
import TextField from "../fields/TextField";
// state done (save token and reload)
export default function RequestPhoneCode({
    param1, param2
}){
    //
    const [fieldType, setFieldType] = useState(TextFieldType.TEXT);
    const [loginState, setLoginState] = useState(PhoneLoginState.initial);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    const [smsCode, setSmsCode] = useState("");
    //
    switch(loginState){
        case PhoneLoginState.initial:
            return <Loader/>;
            break;
        case PhoneLoginState.loading:
            return <Loader/>;
            break;
        case PhoneLoginState.loading_done:
            break;
        case PhoneLoginState.loading_error:
            break;
        case PhoneLoginState.phone_request:
            return <Loader/>;
            break;
        case PhoneLoginState.phone_done:
            break;
        case PhoneLoginState.phone_error:
            break;
        case PhoneLoginState.code_request:
            return <Loader/>;
            break;
        case PhoneLoginState.code_success:
            break;
        case PhoneLoginState.code_error:
            break;
        case PhoneLoginState.redirect:
            window.location.href = "/";
            break;
        default:
            return <Loader/>;
    }
    return <div>
        <h2>Login</h2>
        <div>With phone and sms code</div>
        <div>
            <div>1/2</div>
            IF PHONE ENTERED: ( PHONE NUMBER: +......)
            Enter (PHONE/CODE)...<br/>
            <i>(error text, e.g. wrong code)</i><br/>
            INPUT<br/>
            <div>
                <TextField/>
            </div>
            BUTTON<br/>
        </div>
    </div>
}

/*
<TextField type={TextFieldType.PHONE}

                            codeValue={formData.phone_code}
                            onCodeChange={handleChange}
                            placeholder='Phone number'
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                        />
*/
