const PhoneLoginState = {
    initial: 0,
    loading: 1,
    loading_error: 2,
    loading_done: 3,
    phone_request: 4,
    phone_done: 5,
    phone_error: 6,
    code_request: 7,
    code_success: 8,
    code_wrong: 9,
    code_error: 10,
    redirect: 11
}
export default PhoneLoginState;
