import logo from './images/logo.png';
import './App.css';
import Text28 from './Text28';
import Text24 from './Text24';
import t from './translations/translate.ts';
import { TranslationContextProvider } from 'schummar-translate/react';
import Loader from './Loader.js';
import React from 'react';
import axios from 'axios';
import ErrorPage from './ErrorPage';
import LOAD_ERROR_TYPES from './enums/LoadErrorTypes';
import ButtonPrimary from './buttons/ButtonPrimary.js';
import isDemoEnabled from './demo_mode/is_demo_enabled.js';
import DemoAccount from './demo_mode/demo_account.js';
import CONSTS from './Constants.js';
class App extends React.Component {
  constructor(props) {
    super(props);
    var loginOk = true;
    if (!localStorage.getItem("login_familyToken")) {
      loginOk = false;
    }
    this.state = {
      locale: localStorage.getItem("locale") || 'en',
      family_token: localStorage.getItem("login_familyToken"),
      loginSaved: loginOk || isDemoEnabled(),
      user_token: localStorage.getItem("login_userToken"),
      jsonData: null
    };
  }
  componentDidMount() {
    const fetchData = async () => {
      try {

        if (isDemoEnabled()){
          const d = new DemoAccount();
          var data = {
            "responseFamily": d.family,
            "responseProfile": d.supporter,
          };
          console.log("demo loaded :)");
          this.setState({ jsonData: data });
        }else{
          var userToken = this.state.user_token;
          var familyToken = this.state.family_token;
          const responseFamily = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}`);
          const responseProfile = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}`);
          var data = {
            "responseFamily": responseFamily.data,
            "responseProfile": responseProfile.data,
          };
          this.setState({ jsonData: data });
        }
      } catch (error) {
        // set err state
        this.setState({ jsonData: -1 });
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }
  render() {
    var wasClickedWithFamily = localStorage.getItem('wasClickedWithFamily') || 'not_set';
    if(wasClickedWithFamily ==  localStorage.getItem('login_familyToken') || ''){
      window.location.href = "/tasks";
    }
    if (!this.state.loginSaved) {
      return <ErrorPage errType={LOAD_ERROR_TYPES.AUTH_TOKEN_NOT_FOUND} />;
    }
    if (this.state.jsonData == null) return Loader();
    if (this.state.jsonData == -1) return <ErrorPage errType={LOAD_ERROR_TYPES.AUTH_TOKEN_NOT_FOUND} />;
    var locale = this.state.locale;
    document.body.style.direction = locale === 'iw' ? 'rtl' : 'ltr';


    return (
      <div style={{overflowY: 'scroll', overflowX: 'hidden', 
        direction: localStorage.getItem("locale") == "iw" ? 'rtl' : 'ltr',
      height: '100vh'}} className='AppBody'>
        <header>
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <div className="App">
          <TranslationContextProvider locale={locale}>

            <Text28>{t('helloName', { name: this.state.jsonData.responseProfile.full_name.split(" ")[0] })}</Text28>
            <Text24>{t('thanksForFamily', { family_name: this.state.jsonData.responseFamily.title })}</Text24>
            <p style={{ textAlign: 'left', fontSize: 12 }}>
              {t('signingIn_1')}
              <a style={{ whiteSpace: 'nowrap' }} href='/docview/TERMS'>{t('termsOfUse')}</a>
              {t('signingIn_2')}
              <a style={{ whiteSpace: 'nowrap' }} href='/docview/PRIVACY'>{t('privacyPolicy')}</a>
              {t('signingIn_3')}
            </p>
            <div style={{ height: 18 }}></div>
            <ButtonPrimary
              onClick={() => {
                localStorage.setItem('wasClickedWithFamily', this.state.family_token);
                window.location.href = "/tasks";
              }}>
              {t('alwaysReady')}
            </ButtonPrimary>

          </TranslationContextProvider>
        </div>
      </div>
    )
  }
}
export default App;
