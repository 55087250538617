import React, { useState } from 'react';
import './TheCalendar.css';
import t from './translations/translate.ts';
import { TranslationContextProvider } from 'schummar-translate/react';
import dropdown_icon from './images/dropdown.png';
import icon_prev from './images/icon_prev.png';
import GoTodayButton from './buttons/GoTodayButton.js';
import icon_next from './images/icon_next.png';
import LeftFoatingButton from './buttons/LeftFoatingButton.js';
class TheCalendar extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.date);
    }
    setMonthday(monthDay) {
        this.props.date.setDate(monthDay);
    }
    selectRadioByValue(valueToSelect) {
        var radioToSelect = document.querySelector('input[type="radio"][name="the_calendar_radio_group"][value="' + valueToSelect + '"]');
        if (radioToSelect) {
            radioToSelect.checked = true;
        }
    }
    componentDidMount() {
        document.addEventListener("click", function (e) {
            const dropdown = document.querySelector(".the_calendar_dropdown_container");
            if (!dropdown.contains(e.target)) {
                const content = dropdown.querySelector(".the_calendar_dropdown_content");
                content.style.display = "none";
            }
        });
        const button = document.querySelector(".the_calendar_dropdown_button");
        const content = document.querySelector(".the_calendar_dropdown_content");
        button.addEventListener("click", function () {
            if (content.style.display === "block") {
                content.style.display = "none";
            } else {
                content.style.display = "block";
            }
        });
        document.addEventListener("click", function (e) {
            const dropdown = document.querySelector(".the_calendar_dropdown_container_");
            if (!dropdown.contains(e.target)) {
                const content = dropdown.querySelector(".the_calendar_dropdown_content_");
                content.style.display = "none";
            }
        });
        const button_ = document.querySelector(".the_calendar_dropdown_button_");
        const content_ = document.querySelector(".the_calendar_dropdown_content_");
        button_.addEventListener("click", function () {
            if (content_.style.display === "block") {
                content_.style.display = "none";
            } else {
                content_.style.display = "block";
            }
        });
    }

    render() {
        var l0 = this.props.indicators.no_task_date;
        var l1 = this.props.indicators.tasks_1_2;
        var l2 = this.props.indicators.tasks_3_5;
        var l3 = this.props.indicators.tasks_6_9;
        var l4 = this.props.tasks_10_and_more;
        //
        const monthsArray = [
            { val: 0, str: t("january") },
            { val: 1, str: t("february") },
            { val: 2, str: t("march") },
            { val: 3, str: t("april") },
            { val: 4, str: t("may") },
            { val: 5, str: t("june") },
            { val: 6, str: t("july") },
            { val: 7, str: t("august") },
            { val: 8, str: t("september") },
            { val: 9, str: t("october") },
            { val: 10, str: t("november") },
            { val: 11, str: t("december") }
        ];
        const currentDate = this.props.date;
        const generateCalendar = (date) => {
            const day = date.getDate();
            const year = date.getFullYear();
            const month = date.getMonth();
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);
            const startingDay = 1;
            let currentDate = new Date(firstDay);
            var calendarElements = [];
            var tableRows = [];
            var n = 0;
            const dayIndex = (currentDate.getDay() + 7) % 7;
            while (n < dayIndex) {
                calendarElements.push(<td>&nbsp;</td>);
                n++;
            }
            const lastDayNum = lastDay.getDate();
            for (var i = 1; i <= lastDayNum; i++) {
                const cellStyle = {
                    color: i == day ? "#FFFFFF" : "#313A56",
                    backgroundColor: i == day ? "#496177" : "transparent",
                    cursor: "pointer",
                    width: "32px",
                    height: "32px",
                    fontSize: "14px",
                    textAlign: "center",
                    borderRadius: "12px",
                    margin: "2px",
                };
                const currDay = i;
                calendarElements.push(
                    <td
                        style={cellStyle}
                        onClick={() => {

                            /*setTimeout(() => {
                                document.location.hash = `tasks_of_day_${currDay}`;
                                console.log("hash", `tasks_of_day_${currDay}`);
                            }, 200);*/
                            const content = document.querySelector(".the_calendar_dropdown_content");
                            content.style.display = "none";
                            const newDate = this.props.date;
                            newDate.setDate(currDay);
                            this.props.setDate(newDate);

                        }}
                    >
                        {i}
                        <div style={{
                            width: 4, height: 4,
                            margin: 'auto', borderRadius: 6,
                            display: 'block', background: i == day ? '#fff' : '#090b10',
                            opacity: (
                                l0.includes(i) ? 0 : (
                                    l1.includes(i) ? 35 : (
                                        l2.includes(i) ? 55 : (
                                            l3.includes(i) ? 78 : (
                                                l4.includes(i) ? 99 : 100
                                            )
                                        )
                                    )
                                )
                            ) + '%'
                        }}></div>
                    </td>
                );
                n++;
                if (n % 7 == 0 || i == lastDayNum) {
                    tableRows.push(
                        <tr>{calendarElements}</tr>
                    );
                    calendarElements = [];
                }
            }
            return (<table id="the_calendar_table">
                <thead>
                    <tr id="the_calendar_week">
                        {[
                            t("sunday"), t("monday"), t("tuesday"),
                            t("wednesday"), t("thursday"), t("friday"), t("saturday")
                        ].map((d, index) => (
                            <th>{d}</th>
                        ))}
                    </tr>
                    {tableRows}
                </thead>
                <tbody>
                </tbody>
            </table>);
        };
        const CalendarRadioOptions = () => {
            return (
                <div>
                    {monthsArray.map((month, index) => (
                        <div key={index}>
                            <input
                                type="radio"
                                onChange={
                                    () => {
                                        document.getElementById("the_calendar_month_select_button").click();
                                        currentDate.setMonth(index);
                                        this.props.setDate(currentDate);
                                    }
                                }
                                id={`the_calendar_radio_option${index + 1}`}
                                name="the_calendar_radio_group"
                                checked={this.props.date.getMonth() == index}
                                value={month.val}
                            />
                            <label htmlFor={`the_calendar_radio_option${index + 1}`}>{month.str}</label>
                        </div>
                    ))}
                </div>
            );
        };
        return (

            <TranslationContextProvider locale={this.props.locale || "en"}>
                <div
                    style={{
                        color: "#313A56", userSelect: "none",
                        msUserSelect: "none"
                    }}
                    id='theCalendarFirstDiv'>
                    <div className="the_calendar_dropdown_container">
                        <button id="dropDown_1"
                            className="the_calendar_dropdown_button">
                            <b>{monthsArray[this.props.date.getMonth()].str}, {this.props.date.getFullYear()}</b>
                            <img src={dropdown_icon} />
                        </button>
                        <div className="the_calendar_dropdown_content">
                            <table id="the_calendar_selector">
                                <tr>
                                    <td>
                                        <div className="the_calendar_dropdown_container_">
                                            <button className="the_calendar_dropdown_button_" id="the_calendar_month_select_button">
                                                <b style={{ color: "#313A56" }}>{monthsArray[this.props.date.getMonth()].str}</b>
                                                <img src={dropdown_icon} />
                                            </button>
                                            <div className="the_calendar_dropdown_content_">
                                                <form id="monthSelectForm" className="the_calendar_select_month"
                                                    onsubmit="return false;">
                                                    {CalendarRadioOptions()}
                                                </form>
                                            </div>
                                        </div>
                                    </td>
                                    <td id="the_calendar_prev_next" style={{ direction: "ltr" }}>
                                        <img style={{ verticalAlign: "middle", cursor: "pointer" }} onClick={() => {
                                            currentDate.setFullYear(currentDate.getFullYear() - 1);
                                            if(currentDate.getFullYear() == new Date().getFullYear()){
                                                currentDate.setMonth(11);
                                            }
                                            this.props.setDate(currentDate);
                                        }} src={icon_prev} />
                                        <span style={{ verticalAlign: "middle" }}>{this.props.date.getFullYear()}</span>
                                        <img style={{ verticalAlign: "middle", cursor: "pointer" }} onClick={() => {
                                            currentDate.setFullYear(currentDate.getFullYear() + 1);
                                            currentDate.setMonth(0);
                                            currentDate.setDate(1);
                                            this.props.setDate(currentDate);
                                        }} src={icon_next} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div id="calendar_main_container">
                                            {generateCalendar(this.props.date)}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div style={ localStorage.getItem("locale") == "iw" ? {position: 'absolute', left: 24, top: 2}:{position: 'absolute', right: 24, top: 2}}>
                        <GoTodayButton
                                onClick={() => { this.props.setDate(new Date()) }}
                                dayNum={new Date().getDate()}
                            />        
                        </div>
                    </div>
                </div>
                
            </TranslationContextProvider>

        );
    }
}
export default TheCalendar;
// to do GoTodayButton onClick

/*

<div id='calendar' title='Select current day' style={{ position: 'absolute',
                         right: localStorage.getItem("locale") != "iw" ?25:'',
                         left: localStorage.getItem("locale") == "iw" ?25:'',
                         top: 2 }}>
                            <LeftFoatingButton 
                                onClick={() => { this.props.setDate(new Date()) }}
                                dayNum={new Date().getDate()}
                            />
                        </div>

*/