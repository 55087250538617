// all tasks of selected day
/*

add useEffect and useState

        about

for task list on home page,
with one button

show modal dialog onclick


props:


TaskHomeTypes:
    free task [with view details button],
    my task, [...]
    taken tasks [with "taken" label],

    (also props for modal dialog)

    and setUpdate() function

    html contains:
        time(optional),
        title(required),
        "view deteils" blue secondary button(optional)

    todo...
*/
import React, { useState } from 'react';
import t from './translations/translate.ts';
import TaskState from './enums/TaskState';
import ButtonBlueSecondary from "./buttons/ButtonBlueSecondary";
export default function TaskDayList({
    isOpen, onConfirm = () => { }, onCancel = () => { },
    dayNumber = 12,
    dayWeekNumber = 0,
    isCurrentDay = false,
    taskCards = [],
    id,
    isSelectedDay = true
}) {
    var weekDays = [
        t("sunday"), t("monday"), t("tuesday"),
        t("wednesday"), t("thursday"), t("friday"), t("saturday")
    ];
    return <table id={id} style={{ width: '100%', padding: 6 }}>
        <tbody>
            <tr>
                <td style={{ verticalAlign: 'top', width: 50, display: 'none' }}>
                    <div style={{
                        border: isSelectedDay? '2px solid #313A56':'none',
                        backgroundColor: isCurrentDay ? '#313A56' : '#F7DACB',
                        borderRadius: '4px', color: isCurrentDay ? 'white' : '#313A56',
                        fontWeight: isSelectedDay? 800:'',
                        textAlign: 'center', paddingLeft: 8, marginTop: 20, paddingTop: 5, paddingRight: 8, paddingBottom: 5
                    }}>
                        <div style={{color: isCurrentDay?'white':'#313A56', fontSize: 14}}>{dayNumber}</div>
                        <div style={{fontSize: 12, color: isCurrentDay?'white':'#545b6e'}}>{weekDays[dayWeekNumber]}</div>
                    </div>
                </td>
                <td>
                    {taskCards}
                </td>
            </tr>
        </tbody>
    </table>;
}
