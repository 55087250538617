import ConfirmationModal from "../ConfirmationModal";

export default function ConfirmationTesting(){
    return <div>
        <h1>Confirmation testing</h1>
        <ConfirmationModal
         title="Task name"
         timeBegin = "16:00"
         timeEnd = "18:00"
         isOpen={true}
         //scheduledDate={new Date("1, 1, 2000")}
        ></ConfirmationModal>
    </div>;
}