import ButtonPrimary from "../buttons/ButtonPrimary";
import logo from '../images/logo.png';
import people from '../images/sign_up_pending.png'
import clock from '../images/clock.svg';

import { TranslationContextProvider } from 'schummar-translate/react';
import t from '../translations/translate.ts';

export default function SignUpPendingPage({ familyName = 'TestFamilyName', firstName = 'TestFirstName', lastName = 'TestLastName' }) {
    return  <TranslationContextProvider locale={localStorage.getItem("locale")}>
        <div style={{overflowY: 'scroll', overflowX: 'hidden', height: ' calc(100%)', 
            direction: localStorage.getItem("locale") == "iw" ? 'rtl' : 'ltr'
        }}>
    <table style={{ height: '100vh', width: '100%' }}><tbody>

        <tr><td>

            <div style={{ width: 290, maxWidth: '90%', margin: 'auto', padding: 0 }}>
                <img src={logo} style={{ width: 290 }} alt="logo" />
            </div>
        </td></tr>

        <tr style={{ textAlign: 'center' }}><td>
            <img style={{ height: '28vh' }} src={clock} alt="clock" />
        </td></tr>

        <tr><td>
            <div style={{
                borderRadius: '12px', background: '#F7FCFD',
                boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
                padding: '8px 18px', width: 250, margin: 'auto'
            }}>

                {t('wasSentToFamilly', {full_name: firstName + ' ' + lastName, family_name: familyName})}
            </div>
        </td></tr>

        <tr><td>
            &nbsp;
        </td></tr>

        <tr style={{ height: 120, textAlign: 'right' }}><td>
            <img height={120} src={people} alt="people" />
        </td></tr>

    </tbody>
    </table></div></TranslationContextProvider>
}

/*

{firstName} {lastName}, your request was sent to <b>{familyName}</b> <br /><br />
                Thank you for caring :)

*/
