import './CalendarExtention.css';
import t from '../translations/translate.ts';
function generateColor(count) {
    const color2 = "#FFB791";
    const color1 = "#EEEEEE";
    const parseColor = (color) => ({
        r: parseInt(color.slice(1, 3), 16),
        g: parseInt(color.slice(3, 5), 16),
        b: parseInt(color.slice(5, 7), 16)
    });
    const rgb1 = parseColor(color1);
    const rgb2 = parseColor(color2);
    const stepR = (rgb2.r - rgb1.r) / (count - 1);
    const stepG = (rgb2.g - rgb1.g) / (count - 1);
    const stepB = (rgb2.b - rgb1.b) / (count - 1);
    const rgbToHex = (r, g, b) => "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };
    const generate = (index) => {
        const r = Math.round(rgb1.r + stepR * index);
        const g = Math.round(rgb1.g + stepG * index);
        const b = Math.round(rgb1.b + stepB * index);
        return rgbToHex(r, g, b);
    };
    const colors = [];
    for (let i = 1; i <= count; i++) {
        colors.push(generate(i));
    }
    return colors;
}

function getLastDayOfMonth(year, month) {
    let nextMonthFirstDay = new Date(year, month + 1, 1);
    return new Date(nextMonthFirstDay - 1).getDate();
}

export default function CalendarExtention({ year = 2024, month = 3, monthDay = 2, selectedMonthDay = 30,
    counterResults,
    /*taskDays = [
        { d: 1, count: 4 },
        { d: 4, count: 1 },
        { d: 7, count: 3 },
        { d: 12, count: 5 },
        { d: 15, count: 2 },
    ],*/
    //nextTaskDays = [],
    chooseDay = (d, m, y) => console.log("day choose", `${d}.${m + 1}.${y}`)
}) {
    console.log("res", counterResults);
    const taskDays = counterResults.filter((v) => v.month == month && v.year == year).map((v) => { return { d: v.monthDay, count: v.count } });
    const prevTaskDays = [];
    const nextTaskDays = [];
    function generateMonthDayColor(day_, month_, year_) {
        //return 'transparent';
        var countArray = [];
        //
        if (month_ == month && year_ == year) {
            // current
            countArray = taskDays;
        } else if (month_ > month && year_ == year) {
            // next
            countArray = nextTaskDays;
        } else if (month_ < month && year_ == year) {
            // prev
            countArray = prevTaskDays;
        } else if (year_ > year) {
            // next
            countArray = nextTaskDays;
        } else if (year_ < year) {
            // prev
            countArray = prevTaskDays;
        }
        //
        var d = countArray.filter((v) => v.d == day_);
        if (d.length == 1) {
            return generateColor(7)[Math.min(d[0].count, 7)];
        }
        return 'transparent';
        //
    }

    var lastDayofPrevMonth = getLastDayOfMonth(year, month - 1);
    var lastDayofMonth = getLastDayOfMonth(year, month);
    const dayOfSelectedMonthDay = (new Date(year, month, selectedMonthDay)).getDay();
    const week_1 = [];
    const week_2 = [];
    var _day = null;

    for (var i = 0; i < dayOfSelectedMonthDay; i++) {
        _day = (selectedMonthDay + (i - dayOfSelectedMonthDay));
        if (_day >= 1) {
            week_1.push({ d: _day, month: month });
        } else {
            week_1.push({ d: (lastDayofPrevMonth + _day), month: (month - 1) });
        }
    }
    var m = -1;
    for (var i = 0; i < 7 - dayOfSelectedMonthDay; i++) {
        if ((selectedMonthDay + i) <= lastDayofMonth) {
            _day = (selectedMonthDay + i);
            m = month;
        }
        else if ((selectedMonthDay + i) == lastDayofMonth + 1) {
            _day = 1;
            m = (month + 1);
        } else {
            _day++;
            m = (month + 1);
        }
        week_1.push({ d: _day, month: m });
    }
    for (var i = 0; i < 7; i++) {
        _day++;
        if (_day > lastDayofMonth) {
            _day = 1;
            m = (month + 1);
        }
        week_2.push({ d: _day, month: m });
    }
    return <table className="CalendarExtentionTable">
        <thead>
            <tr className="CalendarExtentionHeader">
                {[
                    t("sunday_"), t("monday_"), t("tuesday_"),
                    t("wednesday_"), t("thursday_"), t("friday_"), t("saturday_")
                ].map(v => <th>{v}</th>)}
            </tr>
        </thead>
        <tbody>
            {[week_1, week_2].map((week_n, index) => <tr key={index * 100} className="CalendarExtentionWeek">
                {week_n.map((v, k) => <td onClick={() => {
                    var year_result = year;
                    var month_result = v.month;
                    if (month_result > 11) {
                        year_result += 1;
                        month_result = month_result - 12;
                    }
                    var result_month_day = v.d;
                    /*setTimeout(() => {
                        document.location.hash = `tasks_of_day_${result_month_day}`;
                        console.log("hash", `tasks_of_day_${result_month_day}`);
                    }, 200);*/
                    chooseDay(result_month_day, month_result, year_result);
                }} className={v.d == monthDay && v.d != selectedMonthDay && v.month == new Date().getMonth() ? 'CalendarExtentionCurrentDay'
                    : (v.d == selectedMonthDay ? 'CalendarExtentionSelectedDay' : '')} key={k}
                    style={{ backgroundColor: (v.d != selectedMonthDay) ? generateMonthDayColor(v.d, v.month, year) : 'transparent' }}
                >{v.d.toString() || ""}</td>)}
            </tr>)}
        </tbody>
    </table>
}
