import ButtonPrimary from "../buttons/ButtonPrimary";
import logo from '../images/logo.png';

import { TranslationContextProvider } from 'schummar-translate/react';
import t from '../translations/translate.ts';

export default function SignUpTaskListPage({ familyInfo, letsGo }) {
    // validate data
    // load tasks
    // states: welcome, taskview, pending...

    // foreach tasks (maybe with map)...

    return <TranslationContextProvider locale={localStorage.getItem("locale")}>
        <div style={{ overflowY: 'scroll', overflowX: 'hidden', 
            direction: localStorage.getItem("locale") == "iw" ? 'rtl' : 'ltr',
            height: ' calc(100%)' }}>
        <div style={{ width: 180, margin: 'auto', marginTop: 10 }}>
            <img src={logo} style={{ width: 180 }} alt="logo" />
        </div>

        <br />
        <div style={{ padding: 15 }}><i> {t('someTasksFrom')} <b>{familyInfo.title}</b>:</i></div>
        <div style={{ height: '40vh', overflowY: 'scroll', marginLeft: 10, marginRight: 10, marginTop: 5 }}>

            {familyInfo.tasks.no_time.filter((t) => t.assignee == null).map((t) => <div
                key={t.id}
                style={{ padding: 10, margin: 20, backgroundColor: '#F5F8FF', borderRadius: 10 }}
            >
                <b style={{ textAlign: 'center' }}>{t.title}</b> <br />
                {t.description}
            </div>)}

            {familyInfo.tasks.events.filter((t) => t.assignee == null).map((t) => <div
                key={t.id}
                style={{ padding: 10, margin: 20, borderRadius: 10, backgroundColor: '#F5F8FF' }}
            >
                <b style={{ textAlign: 'center' }}>{t.title}</b> <br />
                {t.description}
            </div>)}
        </div>
        <br />
        <div style={{ padding: 20 }}>
            {t('youShouldRegister')}<br />
            {t('afterRegistration')}
        </div>
        <div style={{ width: 250, margin: 'auto' }}>
            <ButtonPrimary onClick={letsGo}>{t('signUp')}</ButtonPrimary>
        </div>
        <br /><br />&nbsp;
    </div></TranslationContextProvider>
}