import './SuggestInstall.css';
import { TranslationContextProvider } from 'schummar-translate/react';
import t from './translations/translate.ts';
/*  TODO IN THE PAGE

check if 

* System detection: if android and available:

** suggest pwa download

** suggest apk download

* System detection: if iOS

** suggest appstore download


*/

export function isInPwa() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
        return true;
    }
    return false;
}

export function wasInstallClosed() {
    return localStorage.getItem("SuggestInstall_closed") === "1";
}

function setInstallClosed() {
    localStorage.setItem("SuggestInstall_closed", "1");
}

function isOnApple(){
    return (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
                                                || navigator.userAgent.match(/Macintosh/i));
}

function isPwaInstallAvailable(){
    return typeof window.__install__pwa__ === "function";
}

export function shouldBeInstallDisplayed() {
    return (!isInPwa()) && (!wasInstallClosed());
}

const AppleAppStoreUrl = "https://apps.apple.com/by/app/enola-supporter/id6478454461";
const AndroidApkUrl = "/download/EnolaSupport.apk";

const SuggestInstall = ({ closeBanner = () => { console.log("wil be closed"); } }) => {
    if(!shouldBeInstallDisplayed()){
        return <div style={{height: 0, width: 0}}></div>;

    }
    return <div className='suggest_install_div'>
        <table><tbody style={{ verticalAlign: 'top' }}><tr style={{ verticalAlign: 'top' }}>
            <td style={{ textAlign: 'left' }}>
                {t('neverLoseThisPage')}<br/>
                {isPwaInstallAvailable()?
                 'Add our app to your home screen.':t('install_App')}<br/>
            </td>
            <td style={{ verticalAlign: 'top', width: 37 }}>
                <button onClick={() => { setInstallClosed(); closeBanner(); }} className='suggest_close_button'>&times;</button>
            </td>
        </tr></tbody></table>

        {isPwaInstallAvailable()? 
        <button onClick={()=>window.__install__pwa__()} className="suggest_install_button">{t('install_PWA')}</button>
        :
        <span><br/><a href={isOnApple()? AppleAppStoreUrl:AndroidApkUrl} className='suggest_install_button'>
            {isOnApple()? t('install_iOS'):t('install_Android')}
        </a></span>}
    </div>
}
export default SuggestInstall;
// Available as: PWA, Android App (from apk), iOS app (from AppStore)
