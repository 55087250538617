import React, { useRef, useEffect, useState } from 'react';
import user_default from './images/user_default.png';
import my_tasks_icon from './images/my_tasks_icon.png';
import my_tasks_icon_hover from './images/my_tasks_icon_hover.png';
import t from './translations/translate.ts';
import ButtonBlueSecondary from './buttons/ButtonBlueSecondary.js';
import ProgressBar from './ProgressBar.js';

const UserProfile = ({ initial,in_progress }) =>
    <div style={{
        /*width: document.body.offsetWidth > 600 ? '87%' : 250,*/
        margin: "auto", /*backgroundColor: "#F5F8FF",*/ borderRadius: 12, padding: 16
    }}>
        <table style={{ width: "290px", margin: 'auto', userSelect: 'none', fontSize: 15 }}>
            <tbody>
                <tr>
                    <td style={{ width: '55%', fontSize: 16 }}>
                        <table style={{ width: '100%', padding: 4, backgroundColor: '#F5F8FF', border: '#269ACF solid 1px', borderRadius: 12, height: 62 }}><tbody><tr>
                            <td>&nbsp;<b style={{fontSize: '1.7em'}}>{initial}</b>&nbsp;</td>
                            <td>{t('widget_tasks_available')}</td>
                        </tr></tbody></table>
                    </td>
                    <td>&nbsp;&nbsp;</td>
                    <td style={{ width: '45%' }}>
                        <table style={{ width: '100%', padding: 4, border: '#F5F8FF solid 1px',backgroundColor: '#F5F8FF', borderRadius: 12, height: 62 }}><tbody><tr>
                            <td>&nbsp;<b style={{fontSize: '1.7em'}}>{in_progress}</b>&nbsp;</td>
                            <td>{t('widget_tasks_taken')}</td>
                        </tr></tbody></table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>;
export default UserProfile;

/*

<span style={{ fontSize: "18px", fontWeight: 500 }}
                        >{t('welcomeBackName', {first_name: full_name.split(' ')[0]})}</span><br />


                        <tr>
                    
                    <td colSpan={2} id='profile_name_component' style={{padding: 8}}>
                        
                        
                        
                    </td>
                    <td id='' style={{ display: document.body.offsetWidth > 600 ? "" : "none", width: 150, }}>
                        <ButtonBlueSecondary onClick={() => {
                            window.location.href = "/my_tasks";
                        }}>
                            {t('myTasks')}
                        </ButtonBlueSecondary>
                    </td>
                </tr>
*/


/*

<tr>
                    <td id='my_tasks_button_component' colSpan={2} style={{
                        display: "",
                        paddingTop: 0,
                        
                        paddingBottom: 5
                    }}>
                        <div style={{ width: '240px', marginLeft: 0, marginRight: 0, height: 28 }}>
                            <ButtonBlueSecondary onClick={() => {
                                window.location.href = "/my_tasks";
                            }}>
                                {t('myTasks')}
                            </ButtonBlueSecondary>
                        </div>
                    </td>
                </tr>
                <tr id='progress_bar_component'>
                    <td colSpan={2}>
                        <div style={{
                            marginLeft: localStorage.getItem("locale") != "iw" ? -25 : '',
                            marginRight: localStorage.getItem("locale") == "iw" ? -19 : '',
                            marginTop: 10, marginBottom: 2
                        }}>
                            <ProgressBar
                                isWall={true}
                                locale={locale}
                                part={part}
                                total={total}
                            /></div>
                    </td>
                </tr>

*/