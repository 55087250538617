/*

TODO: 
Hebrew locale

*/

import face from './images/faces/face_1.png';

//import CustomButton2 from "./CustomButton2.js";


import TaskState from "./enums/TaskState.js";
import ButtonSecondary from "./buttons/ButtonSecondary.js";
import CollapseContent from "./CollapseContent.js";
import ButtonBluePrimary from "./buttons/ButtonBluePrimary.js";
import ButtonBlueSecondary from "./buttons/ButtonBlueSecondary.js";
import ButtonIcon from "./enums/ButtonIcons.js";
import { TranslationContextProvider } from 'schummar-translate/react';
import axios from 'axios';
import t from './translations/translate.ts';
import CONSTS from "./Constants.js";
import { useState } from "react";
import CompleteModal from "./CompleteModal.js";
import UnassignModal from "./UnassignModal.js";
import {getColor, getIconSrc, getIconLocaleText} from './CategoryFunctions.js';


export default function TaskCardMyTasks(
    { isOpen, onComplete = () => { }, onCancel = () => { },
        taskId = 123,
        /*taskState = TaskState.NOT_TAKEN,*/
        isEvent = false,
        taskTime = "16:00",
        taskDate = "11 November",
        taskName = "Transportation to hospital",
        taskDescription = "If there is a short comment, it will be located here",
        coordinatorName = "Ifat Edrey",
        coordinatorNumber = "+972 58 5000930",
        highlighted = false,
        taskCategory = "CAT"
    }
) {
    //
    const [reason, setReason] = useState( localStorage.getItem("locale") == "iw"? "אני עסוק מדי😞":"I am too busy 😞");
    const [isCompledOpen, setCompledOpen] = useState(false);
    const [isUnassignOpen, setUnassignOpen] = useState(false);
    //
    var fToken = localStorage.getItem("login_familyToken");
    var uToken = localStorage.getItem("login_userToken");
    //
    function completeTask() {
        var urlTasks = `${CONSTS.BASE_API_URL}/supporters/${uToken}/families/${fToken}/tasks/${taskId}/complete`;
        if (isEvent) {
            urlTasks = `${CONSTS.BASE_API_URL}/supporters/${uToken}/families/${fToken}/events/${taskId}/complete`;
        }
        axios.put(urlTasks)
            .then(response => {
                console.log('complete request successful:', response.data);

                //window.show_notification_success("Complete", `Task "${taskName}" completed`);
                window.show_notification_success( t('completed_'),  t('notiTaskCompleted', {taskName: taskName}) );
                
                setCompledOpen(false);
                onComplete();
            })
            .catch(error => {
                console.error('complete request error:', error);
            });
    }
    function unassignTask() {

        var urlTasks = `${CONSTS.BASE_API_URL}/supporters/${uToken}/families/${fToken}/tasks/${taskId}/unassign_me`;
        if (isEvent) {
            urlTasks = `${CONSTS.BASE_API_URL}/supporters/${uToken}/families/${fToken}/events/${taskId}/unassign_me`;
        }
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        const data = {
            reason: reason
        };
        axios.put(urlTasks, data, headers)
            .then(response => {
                console.log('unassign_me request successful:', response.data);
                window.show_notification_success( t('unassigned_'),  t('notiTaskUnassigned', {taskName: taskName}) );
                setUnassignOpen(false);
                onComplete();
            })
            .catch(error => {
                console.error('unassign_me request error:', error);
            });
    }
    //
    var locale_current = localStorage.getItem("locale") || "en";
    return (
        <TranslationContextProvider locale={locale_current}>

            <CompleteModal
                onCancel={() => {
                    setCompledOpen(false);
                }}
                onConfirm={completeTask}
                isOpen={isCompledOpen}
            />
            <UnassignModal
                reason={reason}
                setReason={setReason}
                onCancel={() => {
                    setUnassignOpen(false);
                }}
                onConfirm={unassignTask}
                isOpen={isUnassignOpen}
            />

            <div style={{ width: '320px', maxWidth: '90%', paddingTop: 26, margin: 'auto' }}>

                

                <table style={{ width: '100%', border: highlighted ? '2px solid #269ACF' : '', borderRadius: 12, boxShadow: highlighted ? '' : '0px 0px 8px 0px rgba(0, 0, 0, 0.25)', padding: 16 }}>
                    <tbody>
                        <tr style={{height: 0}}><td style={{height: 0}} colSpan={3}>
                        <div style={{
                                display: 'inline-block',
                                textAlign: 'center',
                                verticalAlign: 'center',
                                marginTop: -18,
                                marginLeft: (locale_current === 'iw'?0:-19), // locale_current == ?
                                marginRight: (locale_current === 'iw'?-19:0),
                                padding: (locale_current === 'iw'?'8px 6px 8px 0px':'8px 8px 6px 0px'), // locale_current == ?
                                fontSize: '11px',
                                borderTopLeftRadius: (locale_current === 'iw'?0:'10px'),// locale_current == ?
                                borderTopRightRadius: (locale_current === 'iw'?'10px':0),
                                borderBottomRightRadius: (locale_current === 'iw'?0:'10px'), // locale_current == ?
                                borderBottomLeftRadius: (locale_current === 'iw'?'10px':0),
                                color: getColor(taskCategory).text, backgroundColor: getColor(taskCategory).background
                            }}>
                                <table style={{verticalAlign: 'center'}}><tbody><tr>
                                    <td style={{width: 26, textAlign: 'center'}}><img style={{width: 20, height: 20}} src={getIconSrc(taskCategory)} alt="category"/> </td>
                                    <td style={{verticalAlign: 'center'}}>{getIconLocaleText(taskCategory)}</td>
                                </tr></tbody></table>
                            </div>

                        </td></tr>
                        {taskDate != ''?
                        <tr>
                            <td colSpan="2">{taskTime}</td>
                            <td style={{ textAlign: localStorage.getItem("locale") == "iw" ? 'left' : 'right' }}>{taskDate}</td>
                        </tr>:<></>}
                        <tr>
                            <td colSpan="3" style={{
                                color: '#313E44',
                                fontSize: '18px',
                                fontWeight: 500,
                                paddingTop: 10,
                            }}>
                                <CollapseContent
                                    header={taskName}
                                    main={(<table style={{ width: '100%', textAlign: localStorage.getItem("locale") == "iw" ? 'right' : 'left' }}>
                                        <tbody>
                                            <tr>
                                                <td colSpan="3" style={{
                                                    color: '#313E44',
                                                    fontSize: '16px',
                                                    fontWeight: 300,
                                                }}>{taskDescription}</td>
                                            </tr>
                                            <tr style={{ height: 10 }}></tr>
                                            <tr style={{ paddingTop: 10, paddingBottom: 5 }}>
                                                <td><img alt="ava" src={face} /></td>
                                                <td colSpan={2} style={{
                                                    color: '#313E44',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    direction: 'ltr'
                                                }}>
                                                    {coordinatorName}
                                                    <br />
                                                    <span style={{ direction: 'ltr' }}>{coordinatorNumber}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>)}
                                />
                            </td>
                        </tr>
                        <tr style={{ fontSize: 14, height: 60, paddingTop: 10 }}>
                            <td style={{ width: 136 }}> <ButtonBlueSecondary onClick={() => setUnassignOpen(true)}>{t('decline')}</ButtonBlueSecondary></td>
                            <td> &nbsp; </td>
                            <td style={{ width: 136 }}><ButtonBluePrimary icon={ButtonIcon.tick} onClick={() => setCompledOpen(true)}>{t('done')}</ButtonBluePrimary></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </TranslationContextProvider>
    )
}