import React from "react";
import logo from './images/logo.png';
import t from './translations/translate.ts';
import { TranslationContextProvider } from 'schummar-translate/react';
import Loader from "./Loader";
import axios from "axios";
import TaskDateType from "./enums/TaskDateType";
import CompleteModal from "./CompleteModal";
import UnassignModal from "./UnassignModal";
import generateMonthIndication from "./Indication.ts";
import { SimpleGrid } from "@chakra-ui/react";
import ErrorPage from "./ErrorPage";
import LOAD_ERROR_TYPES from "./enums/LoadErrorTypes";
import TaskCardMyTasks from "./TaskCardMyTasks.js";
import ZeroTask from "./ZeroTasks.js";
import isDemoEnabled from "./demo_mode/is_demo_enabled.js";
import DemoAccount from "./demo_mode/demo_account.js";
import CONSTS from "./Constants.js";
import ProfileModal from './ProfileModal.js';
import './DivScroolableStyled.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import SuggestInstall, { shouldBeInstallDisplayed } from './SuggestInstall.js';

class MyTasks extends React.Component {
    constructor(props) {
        super(props);
        var loginOk = true;
        if (!localStorage.getItem("login_familyToken")) {
            loginOk = false;
        }
        this.state = {
            dayIndicators: null,
            calendarDate: new Date(),
            locale: localStorage.getItem("locale") || "en",
            loginSaved: loginOk || isDemoEnabled(),
            family_token: localStorage.getItem("login_familyToken"),
            user_token: localStorage.getItem("login_userToken"),
            taskDateType: TaskDateType.WithDate,
            updateData: null,
            jsonData: null,
            taskCompleteIsOpen: false,
            wrong_network: false,
            taskUnassignIsOpen: false,
            taskCompleteOnConfirmation: () => { },
            taskUnassignOnConfirmation: () => { },
            taskCompleteOnConfirmationTitle: "Title",
            taskH: { type: '-', id: 0 }
        };
    }
    componentDidMount() {
        window.location.hash = '';
        document.title = "My tasks  —  Enola";
        const fetchData = async () => {
            try {
                var responseTasks, responseFamily;
                var responseProfile;
                if (isDemoEnabled()) {
                    const d = new DemoAccount();
                    responseTasks = {
                        "no_time": d.no_time,
                        "events": d.events
                    };
                    responseFamily = d.family;
                    responseProfile = d.supporter;
                } else {
                    var userToken = this.state.user_token;
                    var familyToken = this.state.family_token;
                    responseProfile = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}`);
                    responseTasks = (await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}/tasks`)).data;
                    responseFamily = (await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}`)).data;
                }
                window.show_notification_success = (msg, header) => NotificationManager.success(header, msg);
                var coordinator = responseFamily.coordinator;
                console.log(responseTasks);
                var filteredArrayEvents = responseTasks.events.filter(function (obj) {
                    return obj.assigned_on_me === true && obj.status == "in_progress";
                });

                var detectionsSaved = Array();
                var translationsSaved = Array();

                for (var i = 0; i < filteredArrayEvents.length; i++) {

                    var dateStart = new Date(filteredArrayEvents[i].date + "T" + filteredArrayEvents[i].start_at).toLocaleTimeString("ru");
                    dateStart = dateStart.split(":")[0] + ":" + dateStart.split(":")[1];
                    var dateEnd = new Date(filteredArrayEvents[i].date + "T" + filteredArrayEvents[i].end_at).toLocaleTimeString("ru");
                    dateEnd = dateStart.split(":")[0] + ":" + dateStart.split(":")[1];
                    var d = new Date(filteredArrayEvents[i].date + "T" + filteredArrayEvents[i].start_at);
                    d.setHours(0);
                    d.setMinutes(0);
                    d.setSeconds(0);
                    d.setMilliseconds(0);
                    filteredArrayEvents[i].js_date = d;
                    filteredArrayEvents[i].time_start = dateStart;
                    filteredArrayEvents[i].time_end = dateEnd;
                    console.log("a task", filteredArrayEvents[i]);
                    //
                    var t0 = filteredArrayEvents[i].title;
                    // detect, translate [async]
                    var lng = '';
                    if (typeof detectionsSaved[t0] === 'undefined') {
                        const res = await fetch("https://translate_api.ludenlabs.com/detect", {
                            method: "POST",
                            body: JSON.stringify({
                                q: t0,
                            }),
                            headers: { "Content-Type": "application/json" }
                        });
                        lng = (await res.json())[0].language;
                    } else {
                        lng = detectionsSaved[t0];
                    }
                    detectionsSaved[t0] = lng;
                    var source_lng = lng;
                    if (lng == "he") {
                        lng = "iw";
                    }
                    //
                    if (lng != this.state.locale) {

                        if (typeof translationsSaved[t0] === 'undefined') {
                            const res = await fetch("https://translate_api.ludenlabs.com/translate", {
                                method: "POST",
                                body: JSON.stringify({
                                    q: t0,
                                    source: source_lng,
                                    target: this.state.locale == "iw" ? "he":this.state.locale,
                                    format: "text",
                                    alternatives: 0,
                                }),
                                headers: { "Content-Type": "application/json" }
                            });
                            filteredArrayEvents[i].title = (await res.json()).translatedText;
                            translationsSaved[t0] = filteredArrayEvents[i].title;
                        } else {
                            filteredArrayEvents[i].title = translationsSaved[t0];
                        }
                    }
                    //
                }

                var filteredArrayNoTime = responseTasks.no_time.filter(function (obj) {
                    return obj.assigned_on_me === true && obj.status == "in_progress";
                });

                for (var i = 0; i < filteredArrayNoTime.length; i++) {
                    // detect, translate [async]
                    //
                    var t0 = filteredArrayNoTime[i].title;
                    // detect, translate [async]
                    var lng = '';
                    if (typeof detectionsSaved[t0] === 'undefined') {
                        const res = await fetch("https://translate_api.ludenlabs.com/detect", {
                            method: "POST",
                            body: JSON.stringify({
                                q: t0,
                            }),
                            headers: { "Content-Type": "application/json" }
                        });
                        lng = (await res.json())[0].language;
                    } else {
                        lng = detectionsSaved[t0];
                    }
                    detectionsSaved[t0] = lng;
                    var source_lng = lng;
                    if (lng == "he") {
                        lng = "iw";
                    }
                    //
                    if (lng != this.state.locale) {

                        if (typeof translationsSaved[t0] === 'undefined') {
                            const res = await fetch("https://translate_api.ludenlabs.com/translate", {
                                method: "POST",
                                body: JSON.stringify({
                                    q: t0,
                                    source: source_lng,
                                    target: this.state.locale == "iw" ? "he":this.state.locale,
                                    format: "text",
                                    alternatives: 0,
                                }),
                                headers: { "Content-Type": "application/json" }
                            });
                            filteredArrayNoTime[i].title = (await res.json()).translatedText;
                            translationsSaved[t0] = filteredArrayNoTime[i].title;
                        } else {
                            filteredArrayNoTime[i].title = translationsSaved[t0];
                        }
                    }
                    //
                }

                var data = {
                    "responseProfile": responseProfile.data,
                    "responseFamily": responseFamily.data,
                    "events": filteredArrayEvents,
                    "no_time": filteredArrayNoTime,
                    "taskCounter": responseFamily.tasks_counter,
                    "coordinator": coordinator
                };
                console.log("data", data);
                var ind = generateMonthIndication(this.state.calendarDate.getMonth() + 1, this.state.calendarDate.getFullYear(), data.events);
                this.setState({ jsonData: data, updateData: fetchData, dayIndicators: ind });
                // detect and translate my tasks...
                //
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }
    render() {
        window.showProfileModalDialog = () => { this.setState({ showUserMenuModal: true }) };

        if (this.state.wrong_network) {
            return <ErrorPage errType={LOAD_ERROR_TYPES.NETWORK_ERROR} />;
        }

        if (!this.state.loginSaved) {
            return <ErrorPage errType={LOAD_ERROR_TYPES.AUTH_TOKEN_NOT_FOUND} />;
        }
        if (this.state.jsonData == null) return Loader();

        var taskListWithDate = this.state.jsonData.events;

        for (var i = 0; i < taskListWithDate.length; i++) {
            var d_with_time = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].start_at);
            taskListWithDate[i].d_with_time = d_with_time;
        }
        taskListWithDate.sort((a, b) => a.d_with_time.getTime() - b.d_with_time.getTime());

        var taskListDateless = this.state.jsonData.no_time;

        taskListDateless.sort((a, b) => a.id - b.id);
        taskListDateless.sort((a, b) => b.assigned_on_me - a.assigned_on_me);

        if (taskListDateless.length == 0 && taskListWithDate.length == 0) {
            //
            return <ZeroTask />;
            //
        }

        function areDatesEqual(date1, date2) {
            return (
                date1.getDate() === date2.getDate() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getFullYear() === date2.getFullYear()
            );
        }
        const monthsArray = [
            t("january"),
            t("february"),
            t("march"),
            t("april"),
            t("may"),
            t("june"),
            t("july"),
            t("august"),
            t("september"),
            t("october"),
            t("november"),
            t("december"),
        ];
        const searchParams = new URLSearchParams(window.location.search);

        setTimeout(() => {
            if (searchParams.get('fromPreview') && window._________PREVIEW_LODED != true) {

                var tId = parseInt(searchParams.get('fromPreview'));

                if (tId > 0) {
                    window.location.hash = `_with_date_${tId}`;
                    this.setState({ taskH: { type: 'date', id: tId } });
                } else if (tId < 0) {
                    tId = - tId;
                    window.location.hash = `_dateless_${tId}`;
                    this.setState({ taskH: { type: 'dateless', id: tId } });
                }


                console.log("PREVIEW HIGHLIGHT ENABLED", tId);
                window._________PREVIEW_LODED = true;
            }
        }, 400);
        var taskCounter = this.state.jsonData.taskCounter;
        return (
            <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: ' calc(100%)' }}>

                <ProfileModal
                    setLocale={(value) => this.setState({ locale: value })}
                    userPhone={this.state.jsonData.responseProfile.formatted_phone_number}
                    fullName={this.state.jsonData.responseProfile.full_name}
                    isOpen={this.state.showUserMenuModal}
                    close={() => this.setState({ showUserMenuModal: false })} />
                <TranslationContextProvider locale={this.state.locale} >

                    <SuggestInstall closeBanner={() => this.setState()} />



                    <div id='wall_div_main'
                        className={this.scrollTop > 50 ? 'DivScroolableStyled' : 'DivScroolableStyledHidden'}
                        onScroll={() => {
                            const d = document.querySelector("#wall_div_main");
                            document.querySelector('#wall_div_main').className = d.scrollTop > 40 ? 'DivScroolableStyled' : 'DivScroolableStyled DivScroolableStyledHidden';
                        }}
                        style={{
                            overflowY: 'scroll', overflowX: 'hidden', width: '98%', height: window.innerHeight - 65 -

                                (parseInt(shouldBeInstallDisplayed() ? ((1 / window.innerWidth) * 54000) : 0))

                        }}>

                        <div style={{ direction: this.state.locale == "iw" ? "rtl" : "ltr" }}>

                            <div style={{ textAlign: 'center', paddingTop: 10 }}>
                                <img src={logo} style={{ width: 140, margin: 'auto' }} alt="logo" />
                            </div>

                            <div>
                                <table style={{ width: "290px", margin: 'auto', userSelect: 'none', fontSize: 15 }}>
                                    <tbody>
                                        <tr>

                                            <td style={{ width: '45%' }}>
                                                <table style={{ width: '100%', padding: 4, border: '#F5F8FF solid 1px', backgroundColor: '#F5F8FF', borderRadius: 12, height: 62 }}><tbody><tr>
                                                    <td>&nbsp;<b style={{ fontSize: '1.7em' }}>{taskCounter.initial}</b>&nbsp;</td>
                                                    <td>{t('widget_tasks_available')}</td>
                                                </tr></tbody></table>
                                            </td>

                                            <td>&nbsp;&nbsp;</td>

                                            <td style={{ width: '55%', fontSize: 16 }}>
                                                <table style={{ width: '100%', padding: 4, backgroundColor: '#F5F8FF', border: '#269ACF solid 1px', borderRadius: 12, height: 62 }}><tbody><tr>
                                                    <td>&nbsp;<b style={{ fontSize: '1.7em' }}>{taskCounter.in_progress}</b>&nbsp;</td>
                                                    <td>{t('widget_tasks_taken')}</td>

                                                </tr></tbody></table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4 style={{ padding: 10, marginBottom: 0 }}>{t('myTasks')}</h4>
                            <div >
                                {taskListWithDate.map((item, index) =>
                                    <div key={"key_with_date_" + item.id} id={"_with_date_" + item.id}>
                                        <TaskCardMyTasks
                                            taskCategory={item.category  || 'general'}
                                            isEvent={true}
                                            highlighted={this.state.taskH.type == "date" && this.state.taskH.id == item.id}
                                            onComplete={this.state.updateData}
                                            taskId={item.id}
                                            taskName={item.title}
                                            taskDescription={item.description}
                                            taskTime={item.time_start}
                                            taskDate={
                                                <span> <span>{item.js_date.getDate()}</span> <span>{monthsArray[item.js_date.getMonth()]}</span></span>
                                            }
                                            coordinatorName={this.state.jsonData.coordinator.full_name}
                                            coordinatorNumber={this.state.jsonData.coordinator.formatted_phone_number}
                                        />
                                    </div>
                                )}
                                {taskListDateless.map((item, index) => (
                                    <div key={"key_dateless_" + item.id} id={"_dateless_" + item.id}>
                                        <TaskCardMyTasks
                                            taskCategory={item.category || 'general'}
                                            onComplete={this.state.updateData}
                                            taskId={item.id}
                                            taskName={item.title}
                                            isEvent={false}
                                            highlighted={this.state.taskH.type == "dateless" && this.state.taskH.id == item.id}
                                            taskDescription={item.description}
                                            taskTime=""
                                            taskDate=""
                                            coordinatorName={this.state.jsonData.coordinator.full_name}
                                            coordinatorNumber={this.state.jsonData.coordinator.formatted_phone_number}
                                        />
                                    </div>
                                ))}
                            </div>



                            <CompleteModal
                                onCancel={() => {
                                    this.setState({ taskCompleteIsOpen: false })
                                }}
                                onConfirm={this.state.taskCompleteOnConfirmation}
                                isOpen={this.state.taskCompleteIsOpen == true}
                            />
                            <UnassignModal
                                onCancel={() => {
                                    this.setState({ taskUnassignIsOpen: false });
                                }}
                                onConfirm={this.state.taskUnassignOnConfirmation}
                                isOpen={this.state.taskUnassignIsOpen == true}
                            />

                            <br />
                        </div>

                    </div>

                    <div style={{ position: 'fixed', bottom: 0 }}>
                        <NotificationContainer />
                    </div>

                </TranslationContextProvider>
            </div>
        );
    }
}
export default MyTasks


/*

<td id='user_menu_component' style={{ textAlign: 'right', width: 40, display: 'none' }}>
                                        <UserMenuButton onClick={() => this.setState({ showUserMenuModal: true })} />
                                        <ProfileModal
                                            setLocale={(value) => this.setState({ locale: value })}
                                            userPhone={this.state.jsonData.responseProfile.formatted_phone_number}
                                            fullName={this.state.jsonData.responseProfile.full_name}
                                            isOpen={this.state.showUserMenuModal}
                                            close={() => this.setState({ showUserMenuModal: false })} />
                                    </td>

*/