import './GoTodayButton.css';
import t from '../translations/translate.ts';

export default function GoTodayButton({ dayNum = 1, onClick = () => { } }) {
    return (
        <div style={{ cursor: 'pointer', padding: 3 }} onClick={onClick} >
            <u>{t('button_Today')}</u>
        </div>
    );
};

/*
<svg className="GoTodayButton" style={{margin: 2}} width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" transform="scale(1.1)">
            <path d="M4 0.5H16C17.933 0.5 19.5 2.067 19.5 4V13.6024L16.3131 16.7893L13.6024 19.5H4C2.06701 19.5 0.5 17.933 0.5 16V4C0.5 2.067 2.067 0.5 4 0.5Z" stroke="#313A57" />
            <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#313A57" font-size="12">
                {dayNum <= 9 ? '0':''}{dayNum}
            </text>
        </svg>
*/