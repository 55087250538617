import { createTranslator } from 'schummar-translate/react';
import en from './locales/en.ts';
import iw from './locales/iw.ts';
import de from './locales/de.ts';
import ru from './locales/ru.ts';
import React from 'react';

const translator = createTranslator({
    sourceLocale: 'en',
    dicts: { en, iw, de, ru },
});
//translator.useTranslator("iw");
export default translator.t;

// if translation contains html and html should not be escaped

export function t_html(key: string):React.JSX.Element{
    var s = React.createElement("span", { dangerouslySetInnerHTML: { __html: translator.t(key) } });
    return s;
}