import { TranslationContextProvider } from 'schummar-translate/react';
import t from '../translations/translate.ts';
import './BottomMenu.css';
export default function BottomMenu() {
    return <TranslationContextProvider locale={localStorage.getItem('locale')}>
        <div onContextMenu={(e) => e.preventDefault()} className='bottomMenu_1' style={{
            paddingTop: 11,
            left: 0, right: 0, height: 67
        }}>
            <table style={{ width: '100%' }}><tbody>
                <tr>
                    <td style={{ width: '33.3%', cursor: 'pointer' }}>
                        {window.location.pathname.includes("/tasks") ? <div
                            style={{ height: 6, width: '40%', marginLeft: '30%', marginRight: '30%', borderRadius: 10, backgroundColor: '#F7DACB' }}
                        /> : <div style={{ height: 6 }} />}
                        <a href='/tasks' style={{ textDecoration: 'none' }}>
                            <img style={{ paddingTop: 4 }} width="24" height="24" src="https://img.icons8.com/ios/50/rss.png" alt="rss" />
                            <br />
                            <span style={{ color: 'black' }}>{t('menu_all_tasks')}</span>
                        </a>
                    </td>
                    <td style={{ width: '33.3%', cursor: 'pointer' }}>
                        {window.location.pathname.includes("/my_tasks") ? <div
                            style={{ height: 6, width: '40%', marginLeft: '30%', marginRight: '30%', borderRadius: 10, backgroundColor: '#F7DACB' }}
                        /> : <div style={{ height: 6 }} />}
                        <a href='/my_tasks' style={{ textDecoration: 'none' }}>
                            <svg width="25" height="25" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.4997 7.99948H27.1664M5.56641 7.73281L6.63307 8.79948L9.29974 6.13281M5.56641 15.7328L6.63307 16.7995L9.29974 14.1328M5.56641 23.7328L6.63307 24.7995L9.29974 22.1328M12.4997 15.9995H27.1664M12.4997 23.9995H27.1664" stroke="#313E44" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <br />
                            <span style={{ color: 'black' }}>{t('menu_my_tasks')}</span>
                        </a>
                    </td>
                    <td onClick={() => window.showProfileModalDialog()} style={{ width: '33.3%', cursor: 'pointer' }}>
                        <div style={{ height: 6 }} />
                        <svg width="25" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2.33301C6.477 2.33301 2 6.81001 2 12.333C2 17.856 6.477 22.333 12 22.333C17.523 22.333 22 17.856 22 12.333C22 6.81001 17.523 2.33301 12 2.33301Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.271 18.679C4.271 18.679 6.5 15.833 12 15.833C17.5 15.833 19.73 18.679 19.73 18.679M12 12.333C12.7956 12.333 13.5587 12.0169 14.1213 11.4543C14.6839 10.8917 15 10.1287 15 9.33301C15 8.53736 14.6839 7.7743 14.1213 7.21169C13.5587 6.64908 12.7956 6.33301 12 6.33301C11.2043 6.33301 10.4413 6.64908 9.87868 7.21169C9.31607 7.7743 9 8.53736 9 9.33301C9 10.1287 9.31607 10.8917 9.87868 11.4543C10.4413 12.0169 11.2043 12.333 12 12.333Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <br />
                        <span style={{ color: 'black' }}>{t('menu_profile')}</span>
                    </td>
                </tr>
            </tbody></table>
        </div>
    </TranslationContextProvider>;
}