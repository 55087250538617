import { useEffect, useState } from "react"
import ButtonBluePrimary from "./buttons/ButtonBluePrimary";
import ButtonBlueSecondary from "./buttons/ButtonBlueSecondary";
import face from './images/faces/face_1.png';
import logo from './images/logo.png';
import ButtonIcon from "./enums/ButtonIcons";
import CONSTS from "./Constants";
import axios from 'axios';
import Loader from "./Loader";
import t from './translations/translate.ts';
import { TranslationContextProvider } from "schummar-translate/react";
import NotFound from "./404.js";
// This is preview for urls in apple/google caendar
const PreviewState = {
    NOT_CHECKED: 0,
    OK_EVENT: 1,
    OK_NO_TIME: 2,
    NOT_FOUND: 3,
    WRONG_AUTH: 4,
    NO_AUTH: 5
}
const PreviewTaskStatus = {
    MY_TASK: 0, // [Decline] [Done] buttons, 
    FREE_TASK: 1, // [Take] button
    SOMEONE_ELSEs_TASK: 2 // [Taken label]
}
export default function TaskPreview({ match }) {
    const { family_token, task_id } = match.params;
    const [taskData, setTaskData] = useState(null);
    const [contactData, setContactData] = useState(null);
    const [previewState, setPreiewState] = useState(PreviewState.NOT_CHECKED);
    const [previewTaskStatus, setPreviewTaskStatus] = useState(null);
    useEffect(() => {
        // find task or event...
        // check auth
        //
        async function fetchData() {
            var taskType = "event";
            document.title = "Task preview | Enola";
            //console.log("family", family_token);
            if (localStorage.getItem("login_familyToken") != family_token) {
                return;
            }
            var userToken = localStorage.getItem("login_userToken");

            const { coordinator, primary_caregiver } = (await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${family_token}`)).data;

            setContactData(primary_caregiver || coordinator);

            const { no_time, events } = (await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${family_token}/tasks`)).data;
            setPreiewState(PreviewState.NOT_FOUND);
            var taskId = task_id;
            if (taskId < 0) {
                taskId = - (taskId);
                taskType = "no_time"
            }
            if (taskType == "event") {
                for (var i = 0; i < events.length; i++) {
                    var e = events[i];
                    if (e.id == taskId) {
                        e.type_ = 'event';
                        e.dateStart = new Date(e.date + "T" + e.start_at);
                        e.dateEnd = new Date(e.date + "T" + e.end_at);

                        var d_start = e.dateStart.toLocaleTimeString("ru");
                        var t_start = d_start.split(":")[0] + ":" + d_start.split(":")[1];

                        var d_end = e.dateEnd.toLocaleTimeString("ru");
                        var t_end = d_end.split(":")[0] + ":" + d_end.split(":")[1];

                        e.time_range = t_start + "-" + t_end;

                        console.log("for preview", e);
                        if (e.status == "initial") {
                            setPreviewTaskStatus(PreviewTaskStatus.FREE_TASK);
                        } else if (e.status == "in_progress" && e.assigned_on_me) {
                            setPreviewTaskStatus(PreviewTaskStatus.MY_TASK);
                        } else if (e.status == "in_progress" && !e.assigned_on_me) {
                            setPreviewTaskStatus(PreviewTaskStatus.SOMEONE_ELSEs_TASK);
                        }
                        setTaskData(e);
                        setPreiewState(PreviewState.OK_EVENT);
                        break;
                    }
                }
            } else {
                for (var i = 0; i < no_time.length; i++) {
                    var t = no_time[i];
                    if (t.id == taskId) {
                        console.log("no_time", t);
                        if (t.status == "initial") {
                            setPreviewTaskStatus(PreviewTaskStatus.FREE_TASK);
                        } else if (t.status == "in_progress" && t.assigned_on_me) {
                            setPreviewTaskStatus(PreviewTaskStatus.MY_TASK);
                        } else if (t.status == "in_progress" && !t.assigned_on_me) {
                            setPreviewTaskStatus(PreviewTaskStatus.SOMEONE_ELSEs_TASK);
                        }
                        t.type_ = 'no_time';
                        setPreiewState(PreviewState.OK_NO_TIME);
                        setTaskData(t);
                    }
                }
            }
        }
        fetchData();
        return;
    }, []);
    const monthsArray = [
        t("january"),
        t("february"),
        t("march"),
        t("april"),
        t("may"),
        t("june"),
        t("july"),
        t("august"),
        t("september"),
        t("october"),
        t("november"),
        t("december"),
    ];
    if (previewState == PreviewState.NOT_CHECKED) {
        return <Loader />
    } else if (previewState == PreviewState.NOT_FOUND) {
        return <NotFound />;
    }
    console.log("previewState");
    return <div>
        <TranslationContextProvider locale={localStorage.getItem("locale") || "en"}>
            <table style={{ height: '88vh', width: '100%', padding: 5 }}>
                <tbody>
                    <tr style={{ height: 104 }}>
                        <td style={{ textAlign: 'center' }} colSpan={3}>
                            <img src={logo} style={{ maxHeight: 70 }} alt="logo" />
                        </td></tr>

                    <tr style={{ height: 30 }}><td style={{ paddingLeft: 4, paddingRight: 4 }} colSpan={2}>
                        {taskData.type_ == "event" ? (taskData.dateStart.getDate()) : ''}
                        {" "}
                        {taskData.type_ == "event" ? monthsArray[(taskData.dateStart.getMonth())] : ''}
                    </td><td style={{ width: 80, paddingLeft: 4, paddingRight: 4, textAlign: 'right' }}>
                            {taskData.type_ == "event" ? taskData.time_range : ''}
                        </td></tr>

                    <tr><td style={{ verticalAlign: 'bottom', height: '30vh' }} colSpan={3}>
                        <p colSpan={3} style={{
                            textAlign: 'center',
                            color: '#313E44', fontWeight: 600
                        }}>{taskData.title}</p>
                        <p style={{ paddingLeft: 4, paddingRight: 4 }}>{taskData.description || <i>No description</i>}</p>
                    </td></tr>

                    <tr><td colSpan={3} style={{ height: '30px' }}>
                        <table style={{ width: '100%', height: '30px', verticalAlign: 'bottom', paddingLeft: 4, paddingRight: 4 }} ><tbody>
                            <tr>

                                <td rowSpan={2} style={{ width: '50px' }}> <img style={{ padding: 0 }} src={face} alt=":)" /> </td>
                                <td>{contactData.full_name}</td>
                            </tr>
                            <tr><td style={{ fontSize: 14 }}>{contactData.formatted_phone_number}</td></tr>
                        </tbody></table>
                    </td></tr>


                    {previewTaskStatus == PreviewTaskStatus.MY_TASK ? <tr style={{ height: 40 }}>
                        <td style={{ width: 120, paddingLeft: 4, paddingRight: 4 }}>
                            <ButtonBlueSecondary>Decline</ButtonBlueSecondary>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                        <td style={{ width: 140, paddingLeft: 4, paddingRight: 4 }}>
                            <ButtonBluePrimary icon={ButtonIcon.tick}>Done</ButtonBluePrimary>
                        </td>
                    </tr> : <tr style={{ height: 0 }}></tr>}
                    {previewTaskStatus == PreviewTaskStatus.FREE_TASK ? <tr style={{ height: 40 }}>
                        <td colSpan={3}>
                            <div style={{ width: 170, margin: 'auto', paddingLeft: 4, paddingRight: 4 }}>
                                <ButtonBluePrimary>I'll take it</ButtonBluePrimary>
                            </div>
                        </td>
                    </tr> : <tr style={{ height: 0 }}></tr>}
                    {previewTaskStatus == PreviewTaskStatus.SOMEONE_ELSEs_TASK ? <tr style={{ height: 40 }}>
                        <td>
                            &nbsp;
                        </td>
                        <td style={{ width: 200, paddingLeft: 4, paddingRight: 4 }}>
                            <p>This task is taken by someone else.</p>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr> : <tr style={{ height: 0 }}></tr>}

                    <tr><td colSpan={3}>&nbsp;</td></tr>

                    <tr style={{ height: 55, textAlign: 'center' }}>
                        <td colSpan={3}>
                            <a style={{ color: '#269ACF' }} href={"/my_tasks?fromPreview=" + task_id}>{t('showInMyTasks')}</a>
                        </td>
                    </tr>

                </tbody>
            </table>
        </TranslationContextProvider>
    </div>
}